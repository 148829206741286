.edge-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.edge-detail-table {
  width: 60%;
  margin: 3vw 0 3vw 0;
  text-align: justify;
}

.edge-detail-table tr:nth-child(odd) td:nth-child(1) {
  padding: 2vw 3vw 2vw 0;
}

.edge-detail-table tr:nth-child(odd) td:nth-child(2) {
  padding-top: 2vw;
}

.edge-detail-table tr:nth-child(even) td {
  padding-bottom: 2vw;
}

.edge-detail-image img {
  width: 10vw;
}

.edge-detail-title {
  font-family: "AvenirNextLTProRegular";
  font-size: 1.7vw;
}

.edge-detail-text {
  font-family: "Aptos";
  font-size: 1.4vw;
}
