.navbar {
  height: 8vh;
  background-color: white;
}

.navbar-shadow {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.75);
}

.navbar-container {
  height: 100%;
}

.navbar-brand-div {
  width: 50%;
  height: 100%;
}

.navbar-brand {
  display: block;
  position: relative;
  width: 100%;
  height: inherit;
  background-image: url("/src/assets/images/lh_logo.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  cursor: pointer;
}

.nav-link {
  margin: 0 3em 0 3em;
  font-family: "AvenirNextLTProRegular";
}

.nav-link:hover {
  transform: scale(1.05);
  color: var(--cong-lu-green);
}

.navbar-toggler {
  height: 5vh;
}

.navbar-toggler:hover .lh-logo {
  transform: rotate(-45deg);
}

.lh-logo div {
  height: 0.5vh;
  width: 2.5vh;
  margin: auto;
  border-radius: 1px;
}

.lh-logo div:nth-child(1) {
  margin-bottom: 0.5vw;
}

.navbar-toggler-icon {
  background-image: none;
}

.offcanvas-body {
  text-align: center;
  color: white;
}

@media (max-width: 575px) {
  .lh-logo div:nth-child(1) {
    margin-bottom: 1vw;
  }

  .offcanvas.offcanvas-end {
    border-left: none;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .lh-logo div:nth-child(1) {
    margin-bottom: 0.8vw;
  }
}

@media (max-width: 991px) {
  .offcanvas {
    background: rgba(255, 255, 255, 0.5);
  }

  .offcanvas-header .btn-close {
    background-color: red;
  }

  .offcanvas-body {
    display: flex;
  }

  .offcanvas-body .navbar-nav {
    justify-content: center;
    width: 100%;
  }

  .offcanvas-body .nav-link {
    margin: 1em 2em 1em 2em;
    border-radius: 1vw;
    background: rgb(0, 41, 28);
    background: linear-gradient(
      90deg,
      rgba(0, 41, 28, 1) 0%,
      rgba(2, 86, 69, 1) 35%,
      rgba(4, 191, 138, 1) 100%
    );
  }
}
