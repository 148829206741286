.team-card {
  border-radius: 2.5vw !important;
  overflow: visible !important;
  max-width: 25vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 85%;
  margin: 0 1vw 0 1vw;
}

.team-card-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateY(-5vw);
}

.team-card-content {
  padding: 2vw !important;
  padding-bottom: unset;
}

.team-card-content:last-child {
  padding-bottom: unset;
}

.team-card-content p {
  text-align: center;
  margin-bottom: 0;
}

.team-card-content-position {
  font-family: "Aptos";
  font-size: 1.3vw;
}

.team-card-content-name {
  font-family: "AvenirNextLTProBold";
  color: white;
  font-size: 1.5vw;
  padding-bottom: 1vw;
}

.team-card-content-details {
  color: white;
  font-family: "Aptos";
  font-size: 1.15vw;
  margin-bottom: 0;
  padding-left: 2vw;
}

.team-card-content-details-regular {
  font-family: "Aptos";
}

.team-card-content-details-bold {
  font-family: "AptosBold";
}

.team-card-content-details li:not(:last-child) {
  padding-bottom: 1.5vw;
}
