.intro-bg {
  position: relative;
  margin: auto;
  width: 100%;
  height: 0;
  padding-top: 56.26%; /* (img-height / img-width * container-width) */
  background-image: url("/src/assets/images/plantations_blur.jpg");
  background-size: contain;
  background-repeat: no-repeat;
}

.intro-container {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  position: absolute;
  top: 8vw;
  left: 0;
  right: 0;
}

.intro-title-table tr:nth-child(1) td:nth-child(1) {
  width: 4vw;
}

.intro-title {
  color: white;
  font-size: 2.7vw;
  font-weight: bold;
  font-family: "AvenirNextLTProDemi";
}

.intro-title-logo {
  display: block;
  height: 10vw;
}

.intro-title-table {
  width: 60%;
  margin-bottom: 3vw;
}

.intro-title-divider {
  opacity: 1;
  height: 1vw;
  border-top: none;
  width: 60%;
}

.intro-content {
  width: 60%;
  color: white;
  font-size: 1.8vw;
  font-family: "Aptos";
  text-align: justify;
  line-height: 2.5vw;
  margin-bottom: 3vw;
}

@media (min-width: 1440) {
  .intro-container {
    top: 7vw;
  }
}
