.clients-div {
  margin: 4vw auto;
  display: grid;
  gap: 1;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  justify-content: center;
}

.clients-div img {
  display: flex;
  margin: auto;
  width: 10vw;
}

.client-and-more {
  display: flex;
  margin: auto;
  font-family: "AptosBold";
  font-size: 1.7vw;
}
