.achievement-container {
  margin-top: -3vw;
}

.achievement-table {
  margin: auto;
  margin-top: 2vw;
  margin-bottom: 1vw;
  table-layout: fixed;
  width: 100%;
}

.achievement-table tr:nth-child(1) td {
  padding: 1.5vw 1.5vw 1vw 1.5vw;
  vertical-align: text-top;
  line-height: 2.2vw;
  font-size: 2vw;
  font-family: "AvenirNextLTProRegular";
}

.achievement-table tr:nth-child(2) td {
  padding: 0 1.5vw 0 1.5vw;
}

.achievement-table tr:nth-child(2) td hr {
  opacity: 1;
  margin: 1vw 0;
}

.achievement-table tr:nth-child(3) td {
  padding: 1vw 1.5vw 1.5vw 1.5vw;
  font-family: "AvenirNextLTProBold";
  font-size: 3.8vw;
}

@media (min-width: 921px) and (max-width: 991px) {
  .achievement-table tr:nth-child(3) td {
    font-size: 3.6vw;
  }
}
