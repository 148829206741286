.team-members {
  margin: auto;
  margin-top: 7vw;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.talent {
  font-family: "AvenirNextLTProRegular";
  font-size: 2vw;
  padding-left: 2.5vw;
}

.team-talent-carousel {
  margin: 2vw 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  background: url("/src/assets/images/talent_images_white_bg.jpg");
  background-size: contain;
  background-repeat: repeat-x;
  background-position: 1280px 80px;
  height: 8vw;
  animation: bgScrollLeft 10s linear infinite;
}

.team-talent-carousel:hover {
  animation-play-state: paused;
}

@media (max-width: 575px) {
  .team-talent-carousel {
    animation: bgScrollLeft 20s linear infinite;
  }
}

@keyframes bgScrollLeft {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -1295px 0;
  }
}
